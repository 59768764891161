<template>
    <div class="modal-open-profile-detail">
        <!-- <div class="title">포인트를 소모하여 프로필 정보를 확인합니다.</div> -->
        <div class="body">포인트를 소모하여 프로필 정보를 확인합니다.</div>
        <div class="button-area">
            <button v-for="(btn, idx) in buttons" :key="idx" :class="btn.class" @click="close(btn.class)">
                {{ btn.label }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalOpenProfileDetail',
    props: ['options'],
    data: () => ({}),
    computed: {
        buttons() {
            return [
                {
                    label: '전체 오픈하기',
                    id: 1,
                    class: 'all',
                },
                {
                    label: this.separateItem.title + ' 오픈하기',
                    id: 2,
                    class: 'separate',
                },
            ]
        },
        separateItem() {
            if (this.options.profileType === 'open_region') {
                return { title: '세부지역', conjunction: '을' }
            } else if (this.options.profileType === 'open_smoking') {
                return { title: '흡연 여부', conjunction: '를' }
            } else if (this.options.profileType === 'open_mbti') {
                return { title: 'MBTI', conjunction: '를' }
            } else if (this.options.profileType === 'open_marry_plan') {
                return { title: '결혼 계획', conjunction: '을' }
            } else if (this.options.profileType === 'open_company') {
                return { title: '직장명', conjunction: '을' }
            } else if (this.options.profileType === 'open_bachelor') {
                return { title: '학사', conjunction: '를' }
            } else if (this.options.profileType === 'open_master') {
                return { title: '석사', conjunction: '를' }
            } else if (this.options.profileType === 'open_doctor') {
                return { title: '박사', conjunction: '를' }
            } else if (this.options.profileType === 'open_income') {
                return { title: '소득', conjunction: '을' }
            } else if (this.options.profileType === 'open_asset') {
                return { title: '자산', conjunction: '을' }
            }

            return ''
        },
        settings() {
            return this.$store.getters.settings || {}
        },
    },

    methods: {
        async close(key) {
            const idx = await this.$modal.basic({
                body: `포인트 ${
                    key === 'all' ? this.settings.point.open_all : this.settings.point[this.options.profileType]
                }개를 소모하여 ${
                    key === 'all' ? '프로필 전체 정보를' : this.separateItem.title + this.separateItem.conjunction
                } 오픈합니다.`,
                buttons: [
                    {
                        label: 'CANCEL',
                        class: 'btn-default',
                    },
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx === 1) {
                this.$emit('close', key)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.modal-open-profile-detail {
    width: 100%;
    padding: 20px;
    .title {
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: #111111;
        margin: 4px 0px;
    }
    .body {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #111111;
        margin-top: 8px;
    }
    .button-area {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        padding-top: 20px;
        // display: flex;
        // justify-content: flex-end;
        .all {
            width: 100%;
            border: 1px;
            border-radius: 8px;
            background: #151360;
            padding: 10px;
            color: #ffffff;
            font-weight: 400;
        }
        .separate {
            margin-top: 10px;
            width: 100%;
            border: 1px;
            border-radius: 8px;
            background: #ddd0da;
            padding: 10px;
            color: #151360;
        }
    }
}
</style>
